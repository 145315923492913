import { APP_MODE } from '../constants.utils'

const defaultUrl2 =
  APP_MODE === 'local'
    ? process.env.REACT_APP_API2_LOCAL_URL
    : APP_MODE === 'staging'
    ? process.env.REACT_APP_API2_STAGING_URL
    : APP_MODE === 'live'
    ? process.env.REACT_APP_API2_LIVE_URL
    : window.location.origin + '/api/v1'
const defaultErrorMessage = 'Oops. Something went wrong.'

export { defaultUrl2, defaultErrorMessage }
